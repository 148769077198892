import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './WebFooterDefaultText.scss';

const WebFooterDefaultText = injectIntl(() => {
  return (
    <div id="lh-footer-copyright-text">
      <p>
        <FormattedMessage id="navfooter.copyright.mastercard-1" />
      </p>
      <p data-testid="copyright-line-2">
        <FormattedMessage
          id="navfooter.copyright.mastercard-2"
          values={{
            currentYear: <span>{new Date().getFullYear()}</span>,
          }}
        />
      </p>
    </div>
  );
});

export default WebFooterDefaultText;
